import styled, { keyframes } from "styled-components";

const bounce = keyframes`
    0% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-1rem);
        background-color: var(--color-yellow-3);
    }
    100% {
        transform: translateY(0);
    }
`;

export const LoadingStyled = styled.section`
  display: flex;
  justify-content: center;
  gap: 2.8rem;
  .circle {
    width: 2rem;
    height: 2rem;
    border-radius: 100%;
    background-color: var(--color-yellow-2);
  }

  .first,
  .second,
  .third {
    animation: ${bounce} 1s infinite;
  }

  .second {
    animation-delay: 0.2s;
  }

  .third {
    animation-delay: 0.4s;
  }
`;
