import styled from "styled-components";
import Background from "assets/background.png";
import responsive from "data/responsiveScreenValue";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100dvh;
  background-image: url(${Background});
  background-size: 100dvw calc(100dvw * 432 / 576);
  background-position: center;
  background-repeat: no-repeat;

  .mainSection {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
  }

  .footerSection {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100dvw;
    padding: 18px 0px;
    background-color: #ffffff;

    .footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 953px;
    }

    li {
      font-size: 14px;
      font-weight: 400;
      line-height: 160%; /* 22.4px */
      letter-spacing: -0.28px;
      color: #959595;
      text-align: right;
    }
  }
`;

export const ExplainSection = styled.section`
  position: relative;
  margin-right: 64px;

  svg {
    width: 300px;
  }

  .icon {
    position: absolute;
    top: -70px;
    width: 64px;
    height: 64px;
    border-radius: 12px;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
  }

  .desc {
    margin-top: 18px;
    margin-bottom: 16px;
    font-family: NanumSquareOTF_ac;
    font-size: 22px;
    font-weight: 700;
    line-height: 34.15px;

    .descHighlight {
      font-weight: 800;
    }
  }

  .yellowBox {
    display: inline-flex;
    margin-top: 48px;
    padding: 10px 16px;
    border-radius: 30px;
    background-color: var(--color-yellow-6);
  }

  .descTitle {
    font-size: 18px;
    font-weight: 700;
    line-height: 25.2px;
  }

  .linkSection {
    margin-top: 32px;
    padding: 0px 16px;

    .row {
      display: flex;
      align-items: center;
      margin-top: 24px;

      .QRCode {
        width: 120px;
        height: 120px;
        margin-right: 24px;
        border-radius: 12px;
        background-color: #d9d9d9;
      }

      .listTitle {
        font-size: 16px;
        font-weight: 700;
        line-height: 22.4px;
        opacity: 0.6;
      }

      ul {
      }

      li {
        margin-top: 12px;
        font-size: 16px;
        font-weight: 500;
        line-height: 22.4px;
        opacity: 0.8;
      }
    }

    .downloadSection {
      margin-top: 48px;

      .ready {
        margin-left: 8px;
        font-size: 14px;
        font-weight: 500;
        line-height: 19.6px;
        opacity: 0.3;
      }

      .playStoreBtn {
        display: block;
        margin-top: 24px;
      }

      .playStoreImg {
        width: 162px;
        height: 48px;
      }
    }
  }
`;

export const AppSection = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;

  .appContentContainer {
    position: relative;
    width: ${responsive.width}px;
    height: ${responsive.height}px;
    box-shadow: 1px 3.412px 27.299px 7px rgba(0, 0, 0, 0.1);
    background-color: white;
  }
`;
