import "react-app-polyfill/ie11"; // For IE 11 support
import "react-app-polyfill/stable";

import React, { Suspense } from "react";
import { RecoilRoot } from "recoil";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { createRoot } from "react-dom/client";
import { OverlayProvider } from "overlay-kit";
import {
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import { toast } from "react-toastify";
import { ErrorBoundary } from "react-error-boundary";

import ErrorBoundaryFallback from "components/ErrorBoundary";
import RootLayout from "layout/RootLayout";

import "react-toastify/dist/ReactToastify.css";
import { CustomToastContainer, GlobalStyle } from "components/styles";
// 이외에 필요한 스타일 파일이 있다면 import 해주세요.

const loading = <></>;

// Containers
const DefaultLayout = React.lazy(() => import("./layout/DefaultLayout"));

// Pages

const container = document.getElementById("root");
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 3,
      retryDelay: 500,
      throwOnError: true,
    },
    mutations: {
      throwOnError: false,
    },
  },
  queryCache: new QueryCache({
    onError: (error, query) => {
      if (query.meta?.toastedErrorMessage) {
        if (error as unknown) {
          toast(error.toString());
        }
      }
    },
  }),
});

root.render(
  <RecoilRoot>
    <QueryClientProvider client={queryClient}>
      <OverlayProvider>
        <GlobalStyle />
        <CustomToastContainer
          position="bottom-center"
          autoClose={3000}
          hideProgressBar={true}
          limit={1}
          closeButton={false}
          draggable={false}
        />
        <BrowserRouter>
          <RootLayout>
            <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
              <Suspense fallback={loading}>
                <Routes>
                  <Route path="/*" element={<DefaultLayout />} />
                </Routes>
              </Suspense>
            </ErrorBoundary>
          </RootLayout>
        </BrowserRouter>
      </OverlayProvider>
    </QueryClientProvider>
  </RecoilRoot>
);
