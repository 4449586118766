import { LoadingStyled } from "components/Common/Loading/style";
import React from "react";

export default function Loading() {
  return (
    <LoadingStyled>
      <div className="circle first" />
      <div className="circle second" />
      <div className="circle third" />
    </LoadingStyled>
  );
}
