import styled from "styled-components";
import responsive from "data/responsiveScreenValue";

export const ErrorBoundaryStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100dvw;
  height: 100dvh;

  @media screen and (min-width: ${responsive.min_width}px) {
    width: 100%;
    height: 100%;
  }
`;
