import styled, { createGlobalStyle } from "styled-components";
import { textStyle } from "config/style";
import { ToastContainer } from "react-toastify";
import responsive from "data/responsiveScreenValue";

export const supportDeviceSize = 1080;

const textStyleString = Object.keys(textStyle)
  .reduce((prev, cur) => {
    const style = textStyle[cur as keyof typeof textStyle];
    return [
      ...prev,
      `.${cur} {\nfont-size: ${style.fontSize * 0.1}rem;\nfont-weight: ${
        style.fontWeight
      };\nline-height: ${style.lineHeight * 0.1}rem;\n}`,
    ];
  }, [] as string[])
  .join("\n\n");

export const GlobalStyle = createGlobalStyle`
:root {
    /* Color Guide */

    /* Grey */
    --color-grey-900: #333333;
    --color-grey-800: #4d4d4d;
    --color-grey-700: #666666;
    --color-grey-600: #808080;
    --color-grey-500: #999999;
    --color-grey-400: #b3b3b3;
    --color-grey-300: #bfbfbf;
    --color-grey-200: #cccccc;
    --color-grey-100: #e6e6e6;
    --color-grey-50: #f4f4f4;
    --color-grey-10: #fafafa;

    /* Yellow */
    --color-yellow-1: #fff5d0;
    --color-yellow-2: #ffe897;
    --color-yellow-3: #ffcd1e;
    --color-yellow-4: #eca821;
    --color-yellow-5: #c18000;
    --color-yellow-6: #fff6d9;

    /* Brown */
    --color-brown: #3a210d;
    
    /* Green */
    --color-green: #889e2d;

    /* Red */
    --color-red: #FF4D4D;

    /* Error */
    --color-error: #ff0f0f;

    /* Kakao */
    --color-kakao: #ffe812;
}

*, *::before, *::after {
  box-sizing: border-box;
}

html {
font-size: 62.5%; // 1rem = 10px 로 변경 한 것
// 참고링크 = https://stackoverflow.com/questions/68790660/setting-root-font-size-not-affecting-rem-units-in-safari-for-margin-padding-et
// 128px => 12.8rem , 4px => 0.4rem 가능!
}

body {
  width: 100dvw;
  min-height: 100dvh;
  background: white;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--color-grey-900);

    * {
        font-family: "Pretendard Variable", Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
        margin: 0;
        padding: 0;
        border: none;
        outline: none;
        background-color: transparent;
        box-sizing: border-box;
        line-height: 1.5;
        letter-spacing: -0.05em;
        white-space: pre-wrap;
    }

    a {
        text-decoration: none;
        color: inherit;
    }

    ul, li {
      list-style: none;
    }

    mark {
    position: relative;
    color: var(--color-grey-900);

    &::after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 0.9rem;
      background-color: var(--color-yellow-3);
      z-index: -1;
    }
  }
}

#root {
  width: 100%;
  min-height: 100dvh;
}

${textStyleString}
`;

export const Main = styled.main`
  width: 100dvw;

  @media screen and (min-width: ${responsive.min_width}px) {
    width: ${responsive.width}px;
  }
`;

export const CustomToastContainer = styled(ToastContainer)`
  width: 100dvw;
  height: max-content;
  bottom: 11rem;

  @media screen and (min-width: ${responsive.min_width}px) {
    width: ${responsive.width}px;
  }

  .Toastify__toast {
    margin: 0 2rem;
    min-height: unset;
    height: max-content;
    padding: 1.3rem 1.2rem;
    background-color: var(--color-grey-800);
    border-radius: 0.6rem;
    color: white;
    font-size: 1.6rem;
    line-height: 1.909rem;
    font-weight: 500;

    .Toastify__toast-body {
      all: unset;
      height: max-content;
      font-weight: 500;
    }
  }
`;
