interface TextStyle {
  /** px 단위 */
  fontSize: number;
  fontWeight: number;
  /** px 단위 */
  lineHeight: number;
}

export const textStyle = {
  header1B: {
    fontSize: 28,
    fontWeight: 700,
    lineHeight: 42,
  },
  header1M: {
    fontSize: 28,
    fontWeight: 500,
    lineHeight: 42,
  },
  header2B: {
    fontSize: 24,
    fontWeight: 700,
    lineHeight: 36,
  },
  header2M: {
    fontSize: 24,
    fontWeight: 500,
    lineHeight: 36,
  },
  header3B: {
    fontSize: 22,
    fontWeight: 700,
    lineHeight: 33,
  },
  header3M: {
    fontSize: 22,
    fontWeight: 500,
    lineHeight: 33,
  },
  body1B: {
    fontSize: 20,
    fontWeight: 700,
    lineHeight: 30,
  },
  body1M: {
    fontSize: 20,
    fontWeight: 500,
    lineHeight: 30,
  },
  body2B: {
    fontSize: 18,
    fontWeight: 700,
    lineHeight: 27,
  },
  body2M: {
    fontSize: 18,
    fontWeight: 500,
    lineHeight: 27,
  },
  caption1B: {
    fontSize: 16,
    fontWeight: 700,
    lineHeight: 24,
  },
  caption1M: {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: 24,
  },
} as const satisfies Record<string, TextStyle>;
