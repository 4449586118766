import React, { PropsWithChildren, useState, useEffect } from "react";

import reponsive from "data/responsiveScreenValue";

import { ReactComponent as TitleSvg } from "assets/title.svg";
import { ReactComponent as MetabonSvg } from "assets/metabon_logo.svg";
import { ReactComponent as QRSvg } from "assets/QRCode.svg";
import PlayStore from "assets/playstore_btn.png";
import Logo from "assets/logo.png";

import { Container, ExplainSection, AppSection } from "./styles";

export default function RootLayout({ children }: PropsWithChildren) {
  const [isWideScreen, setIsWideScreen] = useState<boolean>(
    window.innerWidth > reponsive.min_width
  );
  useEffect(() => {
    const a = () => {
      if (window.innerWidth > reponsive.min_width) {
        setIsWideScreen(true);
      } else {
        setIsWideScreen(false);
      }
    };

    window.addEventListener("resize", a);
    return () => window.removeEventListener("resize", a);
  }, []);

  return isWideScreen ? (
    <Container>
      <div className="mainSection">
        <ExplainSection>
          <img className="icon" src={Logo} alt="logo" />
          <p className="desc">
            어른들의 <span className="descHighlight">소일거리 매칭 플랫폼</span>
          </p>
          <TitleSvg />
          <div>
            <div className="yellowBox">
              <span className="descTitle">휴대폰으로 편하게 이용하세요!</span>
            </div>
          </div>
          <div className="linkSection">
            <p className="descTitle">휴대폰에서 접속하기</p>
            <div className="row">
              <QRSvg className="QRCode" />
              <ul>
                <p className="listTitle">다운로드 방법</p>
                <li>
                  - 내 휴대폰 카메라를 킨 후 QR코드가 화면에 나오도록 해주세요
                </li>
                <li>- QR코드가 인식되면, 하단에 링크 버튼이 떠요</li>
                <li>- 버튼을 클릭해서 접속해주세요</li>
              </ul>
            </div>
            <div className="downloadSection">
              <span className="descTitle">앱 다운로드</span>
              <span className="ready">준비중입니다</span>
              {/* <a className="playStoreBtn" href=""> */}
              <button>
                <img
                  className="playStoreImg"
                  src={PlayStore}
                  alt="playstore button"
                />
              </button>
              {/* </a> */}
            </div>
          </div>
        </ExplainSection>
        <AppSection>
          <div className="appContentContainer">{children}</div>
        </AppSection>
      </div>
      <div className="footerSection">
        <div className="footer">
          <MetabonSvg />
          <ul>
            <li>메타본 주식회사 | 대표 : 김진희</li>
            <li>
              사업자등록번호: 889-87-02611 | 통신판매업번호: 2022-경기김포-1702
            </li>
            <li>연락처 : 031-981-9092 | 이메일 : official@metabon.co</li>
          </ul>
        </div>
      </div>
    </Container>
  ) : (
    <>{children}</>
  );
}
